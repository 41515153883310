import React, { Suspense } from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import PageNotFound from 'common/PageNotFound';
import Search from 'App/AppRoutes/Applications/Search';
import Application from 'App/AppRoutes/Applications/Application';
import New from 'App/AppRoutes/Applications/New';

const Applications = () => {
  return (
    <Suspense fallback={<h1>{translate('loading')} . . .</h1>}>
      <Routes>
        <Route exact path="/" element={<Search />} />
        <Route path="new" element={<New />} />
        <Route path=":applicationId/*" element={<Application />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Applications;
