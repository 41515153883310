import qs from 'query-string';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

const { origin } = window.location;

const HTTP_STATUS_UNAUTHORIZED = 401;
const HTTP_STATUS_FORBIDDEN    = 403;
const HTTP_STATUS_NOT_FOUND    = 404;

interface ICustomBaseQueryArgs {
  url: string;
  method?: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  data?: any;
  formData?: FormData;
  queryParameters?: {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    [key: string]: any;
  };
  altBasePath?: string | null;
  headers?: {
    [key: string]: string;
  };
}

const customBaseQuery = async ({
  url,
  method = 'get',
  data,
  formData,
  queryParameters,
  altBasePath = null,
  headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
}: ICustomBaseQueryArgs) => {
  const fetchOptions: RequestInit = {
    method,
    headers,
  };

  let queryString = '';
  if (data !== undefined) fetchOptions.body = JSON.stringify(data);
  if (formData) fetchOptions.body = formData;
  if (queryParameters)
    queryString = `?${qs.stringify(queryParameters, {
      arrayFormat: 'bracket',
    })}`;

  const baseApiUrl = altBasePath ? `${origin}/${altBasePath}` : `${origin}/api`;
  const response = await fetch(
    `${baseApiUrl}/${url}${queryString}`,
    fetchOptions
  );

  if (!response.ok) {
    let errBody;
    try {
      errBody = await response.json();
    } catch (e) {
      errBody = { message: 'Error parsing response' };
    }

    switch (response.status) {
      case HTTP_STATUS_UNAUTHORIZED:
        document.dispatchEvent(new CustomEvent('platform.401'));
        break;
      case HTTP_STATUS_NOT_FOUND:
        document.dispatchEvent(new CustomEvent('platform.404'));
        break;
      case HTTP_STATUS_FORBIDDEN:
        document.dispatchEvent(new CustomEvent('platform.403'));
        break;
    }

    return { error: errBody };
  }

  // Handle successful response
  if (response.headers.get('content-type') === null) return {};
  const originalResponse = await response.json();
  if (originalResponse.meta) {
    return {
      data: {
        meta: originalResponse.meta,
        data: originalResponse.data,
        links: originalResponse.links,
      },
    };
  }
  return originalResponse;
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: customBaseQuery,
  tagTypes: [
    'ApplicationActivity',
    'Applications',
    'ApplicationJobs',
    'ApplicationGroups',
    'ApplicationMaintainers',
    'ApplicationMicroservices',
    'ApplicationMicroserviceJobs',
    'ApplicationMicroserviceDeployments',
    'ApplicationMicroserviceEnvironmentVariables',
    'ApplicationMicroserviceEventNotifications',
    'ApplicationMicroserviceKubernetesRoles',
    'ApplicationMicroservicePods',
    'ApplicationRoles',
    'ApplicationPermissions',
    'Deployments',
    'DmsReplicationConfigs',
    'DmsReplications',
    'IamAccessibleMicroservices',
    'KubernetesRoleConfigs',
    'Groups',
    'NodeGroups',
    'NodeGroupTypes',
    'Organizations',
    'OrganizationApplications',
    'OrganizationGroups',
    'ScheduledExecutions',
    'Series',
    'SupportedDatabases',
    'Teams',
    'TrustedSources',
    'Users',
    'UserApplicationAccess',
  ],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoints: (builder) => ({}),
});

export default apiSlice;
