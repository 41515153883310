import React, { useContext } from 'react';
import propTypes from 'prop-types'
import ApplicationMicroserviceEnvironmentVariables from 'common/applicationMicroservice/ApplicationMicroserviceEnvironmentVariables';
import ApplicationMicroserviceEventNotifications from 'common/applicationMicroservice/ApplicationMicroserviceEventNotifications';
import ApplicationMicroserviceDangerZone from 'common/applicationMicroservice/ApplicationMicroserviceDangerZone';
import { ApplicationMicroserviceConfigurationPropTypes } from 'propTypes/application/microservice/ApplicationMicroservicePropTypes';
import { Accordion, AccordionContext, Alert, Button, Card, Col, Row, useAccordionButton } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import HttpPortForm from '../HttpPortForm';

// eslint-disable-next-line react/prop-types
const AccordionToggleLink = ({ eventKey, activeText, inactiveText }) => {
  const decoratedOnClick = useAccordionButton(eventKey);
  const { activeEventKey } = useContext(AccordionContext);

  const isActive = activeEventKey && activeEventKey.includes(eventKey);

  return (
    <Button
      variant="link"
      onClick={decoratedOnClick}
    >
      {isActive ? activeText : inactiveText}
    </Button>
  );
};

const ApplicationMicroserviceConfiguration = ({ microservice, isDeveloperAuthorized }) => {
  return (
    <Accordion alwaysOpen>
      { // Environment variables
        (['ui', 'api', 'lambda'].includes(microservice.application_microserviceable_type))
        && (
          <>
            <Card bg="dark">
              <Card.Header>
                <Row>
                  <Col className="text-start">
                    {translate('environmentVariables')}
                  </Col>
                  <Col>
                    <span className="text-muted">
                      {microservice.environment_variables_count} {translate('environmentVariables')}
                    </span>
                  </Col>
                  <Col className="text-end">
                    <AccordionToggleLink
                      eventKey="0"
                      inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                      activeText="Close"
                    />
                  </Col>
                </Row>
              </Card.Header>
            </Card>
            <Accordion.Collapse eventKey="0">
              <ApplicationMicroserviceEnvironmentVariables microserviceId={microservice.id} />
            </Accordion.Collapse>
          </>
        )
      }

      { // HTTP port
        (['api', 'ui'].includes(microservice.application_microserviceable_type))
        && (
          <>
            <Card bg="dark" className="mt-3">
              <Card.Header>
                <Row>
                  <Col className="text-start">
                    {translate('httpPort')}
                  </Col>
                  <Col>
                    <span className="text-muted">
                      {microservice.microserviceable.http_port}
                    </span>
                  </Col>
                  <Col className="text-end">
                    <AccordionToggleLink
                      eventKey="1"
                      inactiveText="Edit"
                      activeText="Close"
                    />
                  </Col>
                </Row>
              </Card.Header>
            </Card>
            <Accordion.Collapse eventKey="1">
              {
                isDeveloperAuthorized ? (
                  <HttpPortForm
                    microserviceId={microservice.id}
                    httpPort={microservice.microserviceable.http_port}
                  />
                ) : (
                  <Alert variant="danger">
                    {translate('youAreNotAuthorizedToPerformAction')}
                  </Alert>
                )
              }
            </Accordion.Collapse>
          </>
        )
      }

      { // Event notifications
        (['s3'].includes(microservice.application_microserviceable_type))
        && (
          <>
            <Card bg="dark">
              <Card.Header>
                <Row>
                  <Col className="text-start">
                    Event Notifications
                  </Col>
                  <Col>
                    <span className="text-muted">
                      {microservice.event_notifications_count} event notifications
                    </span>
                  </Col>
                  <Col className="text-end">
                    <AccordionToggleLink
                      eventKey="2"
                      inactiveText={translate('edit')}
                      activeText="Close"
                    />
                  </Col>
                </Row>
              </Card.Header>
            </Card>

            <Accordion.Collapse eventKey="2">
              <Card bg="dark">
                <Card.Body>
                  <ApplicationMicroserviceEventNotifications microserviceId={microservice.id} />
                </Card.Body>
              </Card>
            </Accordion.Collapse>
          </>
        )
      }

      { // Danger zone
        isDeveloperAuthorized && (
          <>
            <Card bg="dark" className="mt-3">
              <Card.Header>
                <Row>
                  <Col className="text-start text-danger">
                    {translate('dangerZone')}
                  </Col>
                  <Col className="text-end">
                    <AccordionToggleLink
                      eventKey="3"
                      inactiveText="Open"
                      activeText="Close"
                    />
                  </Col>
                </Row>
              </Card.Header>
            </Card>
            <Accordion.Collapse eventKey="3">
              <ApplicationMicroserviceDangerZone microservice={microservice} />
            </Accordion.Collapse>
          </>
        )
      }
    </Accordion>
  );
};

ApplicationMicroserviceConfiguration.defaultProps = {
  isDeveloperAuthorized: false,
};

ApplicationMicroserviceConfiguration.propTypes = {
  microservice: ApplicationMicroserviceConfigurationPropTypes.isRequired,
  isDeveloperAuthorized: propTypes.bool,
};

export default ApplicationMicroserviceConfiguration;
