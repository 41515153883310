import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row } from 'react-bootstrap';

import { translate } from '@apex/react-toolkit/lib';
import PaginationControl from 'common/PaginationControl';
import ScheduledExecutionForm from './ScheduledExecutionForm';
import ScheduledExecutionTable from './ScheduledExecutionTable';
import { useAddScheduledExecutionMutation, useLazyGetScheduledExecutionsQuery } from 'api/scheduledJobMicroserviceSlice';
import { ApplicationMicroserviceScheduledJobPropTypes } from 'propTypes/application/microservice/ApplicationMicroservicePropTypes';
import useToast from 'hooks/useToast';
import { useDeleteScheduledJobExecutionMutation } from 'api/scheduledJobExecutionSlice';

const ScheduledExecutionsCard = ({ microservice, env }) => {
  const { microserviceable: schedJobMs } = microservice;
  const [page, setPage] = useState(1);
  const [deleteScheduledJobExecution, { isLoading: deleteLoading }] = useDeleteScheduledJobExecutionMutation();
  const [getScheduledExecutions, { data, isLoading }] = useLazyGetScheduledExecutionsQuery();

  const [addScheduledExecution, { isLoading: mutationLoading, error: apiErrors }] = useAddScheduledExecutionMutation();
  const toast = useToast();
  const loading = isLoading || mutationLoading || deleteLoading;

  useEffect(() => {
    getScheduledExecutions({
      scheduledJobId: schedJobMs.id,
      queryParameters: {
        status: 'pending',
        sort_direction: 'asc',
        page,
        limit: 10,
      }
    });
  }, [page]);

  return (
    <Card bg="dark">
      <Card.Body>
        <Row className="mb-4">
          <Col>
            <ScheduledExecutionForm
              disabled={loading || !schedJobMs[`${env}_tag`]}
              onSubmit={async ({ execute_at }) => {
                await addScheduledExecution({
                  scheduledJobId: schedJobMs.id,
                  data: {
                    target_environment: env,
                    execute_at
                  }
                });

                toast({
                  bg: 'success',
                  title: translate('success'),
                  message: translate('addedExecutionSuccessfully'),
                  autohide: true,
                });
              }}
              apiErrors={apiErrors}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ScheduledExecutionTable
              scheduledExecutions={data ? data.data : []}
              actionColumn={{
                title: translate('actions'),
                dataIndex: 'id',
                render: (id) => {
                  return (
                    <Button
                      disabled={loading}
                      variant="danger"
                      onClick={async () => {
                        await deleteScheduledJobExecution(id);

                        toast({
                          bg: 'success',
                          title: translate('success'),
                          message: 'deleted successfully', //@TODO - update,
                          autohide: true,
                        });
                      }}
                    >
                      {translate('cancel')}
                    </Button>
                  );
                },
              }}
            />
            <PaginationControl
              pagesToShow={3}
              pageCount={data?.meta?.last_page || 1}
              currentPage={data?.meta?.current_page || 1}
              onPageChange={setPage}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
};

ScheduledExecutionsCard.propTypes = {
  microservice: ApplicationMicroserviceScheduledJobPropTypes.isRequired,
  env: PropTypes.oneOf(['dev', 'staging', 'prod']).isRequired,
};

export default ScheduledExecutionsCard;
