import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addRedisMicroservice: builder.mutation({
      query: data => ({ url: 'redis-microservices', method: 'post', data }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
    getRedisMicroservice: builder.query({
      query: id => ({ url: `redis-microservices/${id}`, method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'ApplicationMicroservices', id }],
    }),
  }),
});

export const {
  useGetRedisMicroserviceQuery,
  useAddRedisMicroserviceMutation,
} = extendedApiSlice;
