import React from 'react';
import PropTypes from 'prop-types';
import { Error } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { ApplicationMicroserviceScheduledJobPropTypes } from 'propTypes/application/microservice/ApplicationMicroservicePropTypes';
import { useGetApplicationMicroserviceImageTagsQuery } from 'api/applicationMicroserviceSlice';
import { isValidSemVer } from 'helpers/semver';
import { useEditScheduledJobMicroserviceMutation } from 'api/scheduledJobMicroserviceSlice';
import useToast from 'hooks/useToast';

const ScheduledJobVerisonSelecter = ({ env, microservice }) => {
  const { microserviceable: schedJob } = microservice;
  const { data, isFetching, error } = useGetApplicationMicroserviceImageTagsQuery({ microserviceId: microservice.id });
  const [editScheduledJob, { isLoading: editLoading, error: editError }] = useEditScheduledJobMicroserviceMutation();
  const toast = useToast();

  if (error) return (<Error error={{ message: error.error }} />);
  if (isFetching) return (<Spinner />);

  if (editError) {
    toast({
      bg: 'danger',
      title: translate('error'),
      message: translate('anUnexpectedErrorOccurred'),
      autohide: false,
    });
  }

  const tags = data
    .filter(({ imageTag }) => env === 'dev' ? true : isValidSemVer(imageTag))
    .sort((a, b) => {
      if (a.imageTag < b.imageTag) return 1;
      if (a.imageTag > b.imageTag) return -1;
      return 0;
    });

  const hasLatest = !!data.find(({ imageTag }) => imageTag === 'latest');
  const selectedTag = schedJob[`${env}_tag`];

  return (
    <Form.Group as={Row}>
      <Form.Label column md={"auto"}>Version</Form.Label>
      <Col md={4}>
        <Form.Select
          disabled={editLoading}
          value={selectedTag || ''}
          onChange={async (e) => {
            const imageTag = e.target.value;

            await editScheduledJob({
              ...schedJob,
              [`${env}_tag`]: imageTag,
            });

            toast({
              bg: 'success',
              title: translate('success'),
              message: translate('versionTagUpdatesSuccessfuly', { env, imageTag }),
              autohide: true,
            });
          }
          }>
          <option value="" disabled>{translate('selectVersion')}</option>
          {hasLatest && (<option>latest</option>)}
          {
            tags.map(({ imageTag }) => (<option key={imageTag}>{imageTag}</option>))
          }
        </Form.Select>
      </Col>
    </Form.Group>
  )
};

ScheduledJobVerisonSelecter.propTypes = {
  env: PropTypes.oneOf(['dev', 'staging', 'prod']),
  microservice: ApplicationMicroserviceScheduledJobPropTypes.isRequired,
};

export default ScheduledJobVerisonSelecter;
