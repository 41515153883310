import React from 'react';

import { Formik } from 'formik';
import { DateTime } from 'luxon';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { formControlIsoToLocalIn, formControlLocalToIsoOut } from 'helpers/datetime';
import { translate } from '@apex/react-toolkit/lib';
import * as yup from 'yup';
import FormFeedback from 'common/FormFeedback';

const ScheduledExecutionForm = ({ disabled, onSubmit, apiErrors }) => {
  const schema = yup.object({
    execute_at: yup.string().test({
      test: (value) => DateTime.now().ts <= DateTime.fromISO(value).ts,
      message: translate('executionDateMustBeFuture'),
    }),
  });

  const initialValues = {
    execute_at: DateTime.now().plus({ days: 1 }).toJSDate().toISOString(),
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={(formInput) => {
        onSubmit({
          ...formInput,
          execute_at: formControlLocalToIsoOut(formInput.execute_at),
        });
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Control
                  value={formControlIsoToLocalIn(values.execute_at)}
                  type="datetime-local"
                  name="execute_at"
                  placeholder={translate('description')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabled}
                  isValid={touched.description && !errors.description}
                />
                <FormFeedback
                  touched={touched}
                  errors={errors}
                  apiErrors={apiErrors}
                  fieldName="execute_at"
                />
              </Form.Group>
            </Col>
            <Col md={{ span: 4, offset: 4 }} className="text-end">
              <Button
                variant="primary"
                type="submit"
                disabled={disabled}
              >
                {translate('addExecution')}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default ScheduledExecutionForm;
