import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import ApplicationMicroserviceEnvironmentVariables from 'common/applicationMicroservice/ApplicationMicroserviceEnvironmentVariables';
import { ApplicationMicroserviceScheduledJobPropTypes } from 'propTypes/application/microservice/ApplicationMicroservicePropTypes';
import ScheduledJobVerisonSelecter from './ScheduledJobVerisonSelecter';
import { translate } from '@apex/react-toolkit/lib';
import ConfirmExecuteModal from './ConfirmExecuteModal';
import ScheduledExecutionsCard from './ScheduledExecutionsCard';
import CronCard from './CronCard';

const ScheduledJobConfiguration = ({ env, microservice, isDeveloperAuthorized }) => {
  const { microserviceable: schedJobMs } = microservice;
  const [showConfirmExecuteModal, setShowConfirmExecuteModal] = useState(false);

  return (
    // Not sure why the global bg-dark-secondary class doesn't work here...
    <Card style={{ backgroundColor: 'rgba(108, 117, 125, 0.4)' }}>
      <Card.Body>
        <Row className="mb-4">
          <Col>
            <ScheduledJobVerisonSelecter env={env} microservice={microservice} />
          </Col>
          <Col className="text-end">
            <Button
              disabled={!schedJobMs[`${env}_tag`] || !isDeveloperAuthorized}
              variant="success"
              onClick={() => setShowConfirmExecuteModal(true)}
            >{translate('executeNow')}</Button>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Accordion alwaysOpen>
              <Card bg="dark">
                <Card.Header>
                  {translate('cron')}
                </Card.Header>
              </Card>
              <CronCard
                canActivate={schedJobMs[`${env}_tag`] !== null}
                cron={schedJobMs[`${env}_cron`]}
                env={env}
              />
            </Accordion>
          </Col>
        </Row>
        <Row>
          <Col>
            <Accordion alwaysOpen>
              <Card bg="dark">
                <Card.Header>
                  {translate('scheduledExecutions')}
                </Card.Header>
              </Card>
              <ScheduledExecutionsCard
                microservice={microservice}
                env={env}
              />
            </Accordion>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Accordion alwaysOpen>
              <Card bg="dark">
                <Card.Header>
                  {translate('environmentVariables')}
                </Card.Header>
              </Card>
              <ApplicationMicroserviceEnvironmentVariables microserviceId={microservice.id} lockedEnv={env} />
            </Accordion>
          </Col>
        </Row>
      </Card.Body>
      <ConfirmExecuteModal
        show={showConfirmExecuteModal}
        env={env}
        handleClose={() => setShowConfirmExecuteModal(false)}
        microservice={microservice}
      />
    </Card >
  )
};

ScheduledJobConfiguration.propTypes = {
  env: PropTypes.oneOf(['dev', 'staging', 'prod']).isRequired,
  microservice: ApplicationMicroserviceScheduledJobPropTypes.isRequired,
};

export default ScheduledJobConfiguration;
