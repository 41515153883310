import { FC } from 'react';
import { translate } from '@apex/react-toolkit/lib';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { IApplicationMicroserviceScheduledJob } from 'types/application/microservice/IScheduledJobMicroservice';
import MicroserviceKubernetesRoleConfigAccordion from 'common/infrastructure/roleConfigs/MicorserviceRoleConfigAccordion';
import AccordionToggleLink from 'common/AccordionToggleLink';
import ComputeDetailsForm from 'common/computeBoundMicroservices/ComputeDetailsForm';

const GlobalSettings: FC<{
  microservice: IApplicationMicroserviceScheduledJob;
  isDeveloperAuthorized: boolean;
  isSuperUserAuthorized: boolean;
}> = ({ microservice, isDeveloperAuthorized, isSuperUserAuthorized }) => {
  return (
    <Accordion alwaysOpen className="mb-3">
      {
        isDeveloperAuthorized && (
          <>
            <Card bg="dark">
              <Card.Header>
                <Row>
                  <Col className="text-start">
                    {translate('computeDetails')}
                  </Col>
                  <Col className="text-end">
                    <AccordionToggleLink
                      eventKey="computeDetails"
                      inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                      activeText={translate('close')}
                    />
                  </Col>
                </Row>
              </Card.Header>
            </Card>
            <Accordion.Collapse eventKey="computeDetails">
              <ComputeDetailsForm computeDetails={microservice.microserviceable.compute_details} applicationMicroserviceId={microservice.id} isDeveloperAuthorized={isDeveloperAuthorized} />
            </Accordion.Collapse>
          </>
        )
      }
      { // Kubernetes Roles
        isDeveloperAuthorized && (
          <MicroserviceKubernetesRoleConfigAccordion
            isSuperUserAuthorized={isSuperUserAuthorized}
            microserviceId={microservice.id}
          />
        )
      }
    </Accordion>
  )
};

export default GlobalSettings;
