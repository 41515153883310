import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row, Tab } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ApplicationMicroserviceJobSearchTable from 'common/applicationMicroservice/ApplicationMicroserviceJobSearchTable';
import ApplicationMicroserviceDeploymentSearchTable from 'common/applicationMicroservice/ApplicationMicroserviceDeploymentSearchTable';
import TabsWithNavigationHash from 'common/TabsWithNavigationHash';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import MicroservicePipelineMonitor from 'common/applicationMicroservice/MicroservicePipelineMonitor';
import { IApplicationMicroserviceFilesystem } from 'types/application/microservice/IFilesystemMicroservice';
import FilesystemMicroserviceConfiguration from 'common/applicationMicroservice/filesystem/FilesystemMicroserviceConfiguration';
import MountedMicroservices from 'App/AppRoutes/Applications/Application/Microservices/Detail/FilesystemDetail/MountedMicroservices';

const FilesystemDetail: React.FC<{ microservice: IApplicationMicroserviceFilesystem }> = ({ microservice }) => {
  const { applicationId } = useParams();
  const { isDeveloperAuthorized, isSuperUser } = useIsApplicationMaintainer(applicationId);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Row>
            <Col>
              <h1>{microservice.name}</h1>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <TabsWithNavigationHash
          defaultActiveKey="configuration"
          transition={false}
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >

          <Tab eventKey="configuration" title={translate('filesystemInfo')}>
            <FilesystemMicroserviceConfiguration microservice={microservice} />
          </Tab>
          <Tab eventKey="mounted" title={translate('mountedMicroservices')}>
            <MountedMicroservices microservice={microservice} />
          </Tab>
          <Tab eventKey="jobs" title={translate('jobs')}>
            <ApplicationMicroserviceJobSearchTable microserviceId={microservice.id} />
          </Tab>
          <Tab eventKey="deployments" title={translate('deployments')}>
            <ApplicationMicroserviceDeploymentSearchTable microserviceId={microservice.id} isDbMicroservice canPromote={isDeveloperAuthorized} />
          </Tab>
          {
            isSuperUser && (
              <Tab eventKey="pipelines" title={translate('pipelines')}>
                <MicroservicePipelineMonitor microservice={microservice} />
              </Tab>
            )
          }
        </TabsWithNavigationHash>
      </Row>
    </Container>
  );
};

export default FilesystemDetail;
