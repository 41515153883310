import React, { useContext } from 'react';
import { Accordion, AccordionContext, Alert, Button, Card, Col, Row, useAccordionButton } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';
import CopyableLineItem from 'common/copyableLineItem';
import RedisInstanceMetadataPropTypes from 'propTypes/application/microservice/RedisInstanceMetadataPropTypes';

// eslint-disable-next-line react/prop-types
const AccordionToggleLink = ({ eventKey, activeText, inactiveText }) => {
  const decoratedOnClick = useAccordionButton(eventKey);
  const { activeEventKey } = useContext(AccordionContext);

  const isActive = activeEventKey && activeEventKey.includes(eventKey);

  return (
    <Button
      variant="link"
      onClick={decoratedOnClick}
    >
      {isActive ? activeText : inactiveText}
    </Button>
  );
};

const RedisConnectionDetails = ({ environment, instance }) => {
  return (
    <div className="mb-4">
      <Card bg="dark">
        <Card.Header>
          <Row>
            <Col md="3" className="text-start">
              {`${environment} ${translate('databaseConnectionDetails')}`}
            </Col>
            <Col>
              <span className="text-muted me-4">Redis: {instance ? instance.status : translate('unavailable')}</span>
            </Col>
            <Col className="text-end">
              <AccordionToggleLink
                eventKey={environment}
                inactiveText="View"
                activeText="Close"
              />
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <Accordion.Collapse eventKey={environment}>
        <Card bg="dark">
          <Card.Body>
            {
              !instance ? (
                <Alert variant="danger">
                  {translate('redisNotProvisionedForEnvironment')}
                </Alert>
              ) : [
                'username',
                'password',
                'primaryEndpoint',
                'primaryPort',
                'readerEndpoint',
                'readerPort',
              ]
                .map(field => {
                  return (
                    <CopyableLineItem
                      key={field}
                      field={translate(camelCase(field))}
                      value={instance[field]?.toString() || `${field} ${translate('notAvailableYet')}`}
                      buttonVariant="link"
                      buttonSize="sm"
                      secureField={field === 'password'}
                      isReady={!!instance[field]}
                    />
                  );
                })
            }
          </Card.Body>
        </Card>
      </Accordion.Collapse>
    </div>
  );
};

RedisConnectionDetails.defaultProps = {
  instance: null,
};

RedisConnectionDetails.propTypes = {
  environment: PropTypes.string.isRequired,
  instance: RedisInstanceMetadataPropTypes,
};

export default RedisConnectionDetails;
