import React from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ApplicationMicroserviceHealthCheckStatus from 'common/applicationMicroservice/ApplicationMicroserviceHealthCheckStatus';
import { ApplicationMicroserviceAuthPropTypes } from 'propTypes/application/microservice/ApplicationMicroservicePropTypes';
import useHealthcheck from 'hooks/useHealthcheck';
import styles from './AuthDetail.module.css';
import ContainerLiveView from 'common/computeBoundMicroservices/ContainerLiveView';

const AuthDetail = ({ microservice }) => {
  const isHealthy = useHealthcheck(microservice.id, 'prod');

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Row>
            <Col>
              <h1>
                {microservice.name}
                {(isHealthy !== null) && (
                  <span className={`ms-3 align-middle ${styles.healthCheckStatusContainer}`}>
                    <ApplicationMicroserviceHealthCheckStatus isHealthy={isHealthy} />
                  </span>
                )}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>{translate('route')}:</strong> <span>/auth</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>{translate('prodDeployedVersion')}:</strong> <span>{microservice.prod_tag || <Badge bg="warning" text="dark">{translate('notDeployed')}</Badge>}</span>
            </Col>
          </Row>
          <Row>
            <ContainerLiveView microservice={microservice} />
          </Row>
        </Col>
      </Row >
    </Container >
  );
};

AuthDetail.propTypes = {
  microservice: ApplicationMicroserviceAuthPropTypes.isRequired,
};

export default AuthDetail;
